@import "app/scss/colors.scss";
@import "app/scss/fonts.scss";

html,
body {
  //background-color: $light-grey;
  margin: 0;
  padding: 0;
  height: auto;
  font-family: $ptSans;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.disable {
  cursor: default;
  user-select: none;
  border: 1px solid rgba(118, 118, 118, 0.3)!important;
  background: #f8f8f8;
  color: #545454;
}

.pac-container { z-index: 100000!important; }


