$white: #FFFFFF;
$light-grey: #F5F5F5;
$light-blue: #DEE3FF;
$grey: #B4B4B4;
$darker-grey: #D0D0D0;
$darkest-grey: #808080;
$dark-grey: #272727;
$middle-grey: #767676;
$blue: #3794FF;
$blue-light: #72b5ff;
$red: #CF5D5D;
$light-red: #fa7575;
$white-orange-light: #f1d3ca;
$orange-light: #f2966b;
$orange: #ED672D;
$green: #56BB8A;
$bright-blue: #5cb0f5;
$bright-green: #72fc63;
$bright-green-2: #4DCA76;
$bright-red: #ff0000;
$white-blue: #C1D7EB;
$white-light-blue: #EAF5FF;
$white-red:  #f6b8b8;
$white-green: #d3ffd8;
$dark-blue: #003a6e;
$darkest-blue: #2B2B36;
$dark-green: #015a08;
$dark-red: #7a0000;
$client-line-color: rgb(170, 170, 170);
$client-grey: rgba(128, 128, 128, 1);
$purple: rgba(176, 112, 230, 1);



$turquoise: rgba(58, 190, 177, 1);


.font-color {
  &__red {
    color: $bright-red;
  }
  &__green {
    color: $bright-green;
  }
}
